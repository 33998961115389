import "./AppRedirect-styles.css"
import { Stack } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

function AppStoreRedirect({data}){
    const params = useParams();
    return(
        <div className="modalWrapper">
            <h2>Pressures is not currently installed on your device.</h2>
            <a className="button" 
                // href="https://testflight.apple.com/join/OS6HAGHa"
                href="https://pressures.app/dl"
            >Click here to install.</a>
            <a className="secondaryLink" href={`/p/${params.pressureId}`}>Not now</a>
        </div>
    )
}

export default AppStoreRedirect;