import logo from './logo.svg';
import './App.css';
import { ChakraProvider, Stack} from '@chakra-ui/react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import PressureDetails from './Pages/PressureDetails/PressureDetails';
import AppStoreRedirect from './Pages/AppStoreRedirect/AppStoreRedirect';
import React, {useState, useEffect} from 'react';
import { fetchPressureWithId } from './Components/api/fetch';
import theme from './theme'
import '@fontsource/raleway/400.css'
import '@fontsource/open-sans/700.css'

function App() {

  const [pressureId, setPressureId] = useState(undefined)
  const [pressureResponse, setPressureResponse] = useState({})
  const location = useLocation()

  useEffect(() => {
    let url = window.location.href
    if (url.includes('/p/')){
      setPressureId(url.split('/p/')[1])
    }
    
  }, [location])

  useEffect(() => {
    if(pressureId !== undefined && pressureId.length > 0){
      fetchAsync(pressureId)
    }
  }, [pressureId])

  const fetchAsync = async (id) => {
    const response = await fetchPressureWithId(id)
    setPressureResponse({...response})
  }

  return (
    <ChakraProvider theme={theme}>
      <Stack
        alignItems={'center'}
        w={'100vw'}
        h={'100vh'}
        p={5}
        backgroundColor={'lightblue'}>
        <Routes>
          <Route path={`/p/:pressureId`} element={<PressureDetails data={pressureResponse?.data?.pressure}/>}/>
          <Route path={`/appRedirect/:pressureId`} element={<AppStoreRedirect/>}/>
        </Routes>
      </Stack>
    </ChakraProvider>
  );
}

export default App;
